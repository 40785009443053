import request from "@/api/request";
import download from "@/api/download";

export function add(data) {
  return request({
    url: "/market-service/epc/planning/managePlan/save",
    method: "post",
    data,
  });
}

export function edit(data) {
  return request({
    url: "/market-service/epc/planning/managePlan/modify",
    method: "post",
    data,
  });
}

export function fetchDetail(params) {
  return request({
    url: `/market-service/epc/planning/query/${params.id}`,
  });
}
export function fetchDetailByPid(params) {
  return request({
    url: `/market-service/epc/planning/queryByPid/${params.id}/${params.type}`,
  });
}

// 下载模版
export function downloadTemplate() {
  return download({
    url: "/market-service/epc/planning/managePlan/export/template",
  });
}
// 导出
export function exportFile(params) {
  return download({
    url: `/market-service/epc/planning/managePlan/export/${params.id}`,
  });
}
// 下载模版
export function downloadTemplateChange() {
  return download({
    url: "/market-service/epc/planning/managePlanUpdate/export/template",
  });
}
// 导出
export function exportFileChange(params) {
  return download({
    url: `/market-service/epc/planning/managePlanUpdate/export/${params.id}`,
  });
}
