<template>
    <a-form :form="form"
              @submit="handleSubmit"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
              :colon="false">
        <a-row>
          <a-col>
            <a-form-item label="当前项目"
                         class="default-highlight"
                         :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }">
              <a-input :disabled="true"
                       :placeholder="designCode"></a-input>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item label="工程名称"
                         class="default-highlight"
                         :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }">
              <a-input :disabled="true"
                       :placeholder="projectName" />
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item label="主要内容"
                         :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }">
              <a-textarea :auto-size="{ minRows: 5 }"
                          v-decorator="[
                  'content',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]" />
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item :label-col="{ span: 4 }"
                         :wrapper-col="{ span: 18 }">
              <span slot="label"
                    class="">附件</span>
              <div class="link-list"
                   v-decorator="[
                    'file',
                    {
                      rules: [
                        {
                          required: true,
                          message: '请上传附件！',
                        },
                      ],
                    },
                  ]">
                <a-checkbox-group :value="selectedFileList"
                                  @change="onSelectFile"
                                  v-if="fileList.length !== 0">
                  <div class="link"
                       v-for="item in fileList"
                       :key="item.id">
                    <a-checkbox :value="item.id">
                      <a target="_blank"
                         download
                         :href="item.completePath">{{
                        item.name
                      }}</a>
                    </a-checkbox>
                  </div>
                </a-checkbox-group>
                <div style="color:#ccc;"
                     v-else-if="fileList.length === 0 && id">
                  无
                </div>
                <div style="color:#ccc;"
                     v-else>
                  《总承包项目管理计划》
                </div>

                <div class="control-bar">
                  <FileUpload @uploaded="uploaded">
                    <div class="control">
                      <a-icon type="upload" />
                    </div>
                  </FileUpload>

                  <div class="control"
                       @click="deleteFile">
                    <a-icon type="delete" />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button htmlType="submit"
                      type="primary"
                      v-if="!isView">提交</a-button>
          </a-space>
        </div>
      </a-form>
</template>

<script>
import { saveAs } from "file-saver";
import { fetchDetail } from "@/api/epc";
import {
  add,
  edit,
  fetchDetailByPid,
  exportFile,
  downloadTemplate,
} from "@/api/epc/plan/managePlan";

import { mapGetters } from "vuex";

import FileUpload from "@/components/file-upload";

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      imgUrl: require("@/assets/epc/approvalFlow/2-2.png"),

      stage: "epc_manage_plan",
      isView: false,

      form: this.$form.createForm(this),
      pid: "", // 项目id
      id: "", //当前表单的id
      hid:'',
      projectName: "",
      designCode: "",

      fileList: [],
      selectedFileList: [],
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    stageName() {
      return this.findSingleDictName("epc_stage", this.stage);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id,hid, isView } = query || {};

    this.pid = pid||''
    this.id = id||''
    this.hid= hid||''
    this.isView = Boolean(isView) || false;

    this.getName();
    this.getDetailByPid();
  },
  methods: {
    getDetailByPid() {
      fetchDetailByPid({ id: this.pid ,type: this.stage})
        .then((res) => {

          this.id = res.id;
          this.hid = res.historyId

          this.form.setFieldsValue({
            content: res.content,
            file: res.attachmentList,
          });
          if (res.attachmentList) {
            this.fileList = res.attachmentList;
          }
        })
        .catch();
    },
    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },

    exportForm() {
      exportFile({ id: this.id })
        .then((blob) => {
          saveAs(blob, `${this.projectName}_${this.stageName}.docx`);
        })
        .catch();
    },
    download() {
      console.log(this.projectName, this.stageName);

      downloadTemplate()
        .then((blob) => {
          saveAs(blob, `${this.stageName}模版.docx`);
        })
        .catch();
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          delete values.file;
          if (!this.id) {
            add({
              ...values,
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              type:this.stage,
            }).then(() => {
              this.getDetailByPid()
            });
          } else {
            edit({
              ...values,
              pid: this.pid,
              id: this.id,
              historyId:this.hid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              type:this.stage,
            }).then(()=>{
              this.getDetailByPid()
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
